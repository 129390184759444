<template>
  <div id="dropdown">
    <img class="menuIcon mx-md" src="/images/menu_icon.png" @click="toggleSubMenu" />
    <div id="subMenu" :class="{ 'active' : subMenuOpen }">
      <router-link class="mx-md fade-in" to="/">home</router-link>
      <router-link class="mx-md fade-in" to="/about">about us</router-link>
      <router-link class="mx-md fade-in" to="/community">our community</router-link>
      <router-link class="mx-md fade-in" to="/events">events</router-link>
      <div class="donate-button mt-md ml-md fade-in">
        <a class="fade-in" href="https://buy.stripe.com/5kA9C3bg69pXchy5kk" target="_blank">donate</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
export default {
  name: "Dropdown",
  props: {
    position: {
      type: String,
      require: false
    }
  },
  setup() {
    let subMenuOpen = ref(false);
    const cartVisible = inject("cartVisible");

    function toggleSubMenu(){
      subMenuOpen.value = !subMenuOpen.value;
    }

    function addListeners() {
      const dropdown = document.querySelector("#dropdown");
      const subMenu = document.querySelector("#subMenu");

      dropdown.addEventListener('click', (event) => {
        event.stopPropagation(); // Prevent clicks inside the modal from closing it
      });
      subMenu.addEventListener('click', (event) => {
        event.stopPropagation(); // Prevent clicks inside the modal from closing it
      });

      window.addEventListener('click', () => {
        subMenuOpen.value = false;
      })
    }

    onMounted(function() {
      addListeners();
    })

    return {
      subMenuOpen,
      cartVisible,
      toggleSubMenu
    }
  }
}
</script>

<style lang="scss">
#dropdown {
  width: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menuIcon {
  width: 23px;
}
#subMenu {
  position: absolute;
  background: #897eaec2;
  text-align: left;
  width: auto;
  z-index: 2;
  border-top: 0;
  top: 39px;
  left: 0px;
  height: 0px;
  overflow: hidden;
  transition: all ease-in-out .5s;
  display: flex;
  flex-direction: column;
  &.active {
    padding: 20px 0;
    height: 200px;
    transition: all ease-in-out .5s;
  }
  .donate-button {
    border: 1px solid $accent;
    border-radius: 5px;
    padding: 4px 13px;
    text-align: center;
    a {
      color: $accent !important;
    }
  }
}
</style>